import React from 'react'

import Footer from './Footer'
import avatar from '../assets/images/avatar.png'

class Header extends React.Component {
    render() {
        return (
            <header id="header">
                <div className="inner">
                    <a href="/" className="image"><img src={avatar} alt="Vina Sana" /></a>
                    <h1>Invoer en groothandel van wijnen</h1>
                </div>
                <Footer />
            </header>
        )
    }
}

export default Header
